import React from 'react'

type Props = {
  title: string
  imgUrlPath?: string
  btnTitle?: string
  btnAction?: any
}

const SubHeader: React.FC<Props> = ({ title, imgUrlPath, btnTitle, btnAction }) => {
  return (
    <div
      className="section-bg section-padding subheader"
      style={{
        backgroundImage: imgUrlPath ? `url(${imgUrlPath})` : `url(/images/subheader.webp)`,
      }}
    >
      <div className="breadcumb-overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="text-white page-title">{title}</h1>
            {btnTitle && btnAction && (
              <button
                style={{ marginTop: '20px' }}
                onClick={(e) =>
                  typeof btnAction === 'function' ? btnAction() : e.preventDefault()
                }
                className="btn-breadcumb"
              >
                {btnTitle}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubHeader
