import React from 'react'
import Layout from '@/components/Layout'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import Header from '@/components/Header'
import PrivacyPolicyPage from '@/components/PrivacyPolicy'
import SubHeader from '@/components/Common/SubHeader'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" canonical={`${useLocation().host}`} />
      <Header />
      <SubHeader title="Privacy Policy" />
      <PrivacyPolicyPage />
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy
